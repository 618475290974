@import "@flaticon/flaticon-uicons/css/all/all";
/* @import url('https://fonts.cdnfonts.com/css/avenir'); */


@font-face {
  font-family: 'Avenir';
  src: url('assets/fonts/Avenir/Avenir\ Regular/Avenir Regular.ttf') format('truetype');

}


body {
  font-family: 'Avenir', sans-serif;
  color: #23252D;
  margin: 0;
  padding: 0;
  background: #fff;
  /* overflow: hidden; */
}

a {
  color: #23252d;
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: #305ff0;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #23252d;
}

#kitaab-navbar {
  background: #f7f8fa;
  box-shadow: 0px 7px 26.7px 0px rgba(48, 95, 240, 0.12);
  padding: 24px 80px;
}

#kitaab-navbar ul li a {
  color: #23252d;
  padding: 4px 16px;
  font-size: 16px;
  line-height: 24px;
}

#kitaab-navbar .navbar-nav {
  margin-left: 32px;
  margin-right: 32px;
}

.f-400 {
  font-weight: 400;
}

.f-500 {
  font-weight: 500;
}

.f-600 {
  font-weight: 600;
}

.f-700 {
  font-weight: 700;
}

.f-800 {
  font-weight: 800;
}

.f-900 {
  font-weight: 900;
}

.font-size-12 {
  font-size: 12px;
}

.f-12-imp {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.b-r-20 {
  border-radius: 20px;
}

.b-r-15 {
  border-radius: 15px;
}

.b-r-4 {
  border-radius: 4px;
}

.b-r-10 {
  border-radius: 10px;
}

.b-r-3 {
  border-radius: 3px;
}

.b-r-8 {
  border-radius: 8px;
}

.form-control {
  font-size: 14px;
  border: 1px solid #D3D4D6;
  color: #23252D;
  background: #fff;
  border-radius: 8px;
  padding: 5px 10px;
  line-height: 20px;
}

.h-40px {
  height: 36px;
}

.h-45 {
  height: 45px;
}

.me-32 {
  margin-right: 32px;

}

.ms-32 {
  margin-right: 32px;
}

.me-16 {
  margin-right: 16px;
}

.btn-primary {
  background-color: #305FF0;
  border-color: #305FF0;
}

.btn-outline-secondary-light:hover {
  border-color: #D3D4D6;
}

.outline-group .btn-outline-light:hover {
  border-color: #d3d4d6;
}

.text-warning {
  color: #F3893D !important;
}

.btn-outline-warning {
  border-color: #F3893D;
  color: #F3893D;
}

.btn-outline-primary {
  border-color: #305FF0;
  color: #305FF0;
}

.btn-outline-primary:hover {
  background: #EBF0FF !important;
  border-color: #305FF0 !important;
  color: #305FF0 !important;
}

.btn-outline-secondary:hover {
  background: #EBF0FF !important;
  border-color: #d3d4d6 !important;
  color: #23252D !important;
}

.btn-outline-secondary:active,
.btn-outline-secondary:focus-visible,
.btn-outline-secondary:focus {
  background: #EBF0FF !important;
  border-color: transparent !important;
  color: #305FF0 !important;
  box-shadow: 0 0 0 2px #3060f08e !important;

}



.btn-outline-primary:active,
.btn-outline-primary:focus-visible,
.btn-outline-primary:focus {
  background: #EBF0FF !important;
  border-color: transparent !important;
  color: #305FF0 !important;
  box-shadow: 0 0 0 2px #3060f08e !important;

}

.btn-outline-primary:active,
.btn-outline-primary:focus-visible {
  background: #EBF0FF !important;
  border-color: transparent !important;
  color: #305FF0 !important;
}

.close-white:before {
  color: #fff;
}

.btn-primary:hover {
  background: #305FF0;
  border-color: #305FF0;
}

.btn-padding {
  padding: 8px 16px;
}

.footer {
  background: #23252d;
}

.m-t-32 {
  margin-top: 32px;
}

.m-t-24 {
  margin-top: 24px;
}

.m-t-64 {
  margin-top: 64px;
}

.p-t-b-64-104 {
  padding-top: 104px;
  padding-bottom: 64px;
}

.footer-list li {
  background-color: transparent;
  margin-bottom: 18px;
  border-bottom: 0;
  padding: 0;
}

.list-group-flush>.list-group-item {
  margin-bottom: 4px;
}

.footer-list li a {
  color: #fff;
  font-size: 12px;
  line-height: 20px;
}

.main-heading-top {
  margin-top: 96px;
}

.text-color-1 {
  color: #23252d;
}

.text-color-2 {
  color: #515561;
}

.text-color-3 {
  color: #6f7380;
}

.l-24 {
  line-height: 24px;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-12 {
  margin-top: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.font-size-64 {
  font-size: 64px;
}

.l-h-130 {
  line-height: 130%;
}

.btn-padding {
  padding: 8px 24px;
}

.mb-48 {
  margin-bottom: 48px;
}

/* .refresh-section {
  padding-top: 80px;
} */

.l-h-32 {
  line-height: 32px;
}

.refresh-section {
  background: #EEF3FF;
}

.p-80 {
  padding: 80px;
}

.refreshing-tabs .nav-item a {
  background: #fff;
  padding: 20px 6px;
  font-size: 18px;
  color: #515561;
  border-radius: 12px;
  height: 100%;
}

.refreshing-tabs .nav-link.active {
  background: #305ff0;
}

.refreshing-tabs ul.nav {
  gap: 24px;
}

.refreshing-tabs .tab-content {
  margin-top: 32px;
  border-radius: 16px;
  background: #fff;
}

.l-h-24 {
  line-height: 24px;
}

.p-56 {
  padding: 56px;
}

.m-t-48 {
  margin-top: 48px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-6 {
  margin-top: 6px;
}

.primary-bg {
  background: #305FF0;
}

.p-64 {
  padding: 64px;
}

.p-16 {
  padding: 16px;
}

.text-grey {
  color: #e2e3e4;
}

.font-size-48 {
  font-size: 48px;
}

.text-f7 {
  color: #f7f8fa;
}

.b-r-24 {
  border-radius: 24px;
}

.steps-image {
  background: #D8E2FF;
  padding: 12px;
  border-radius: 8px;
}

.pr-16 {
  padding-right: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.b-r-12 {
  border-radius: 12px;
}

.b-r-12-imp {
  border-radius: 12px !important;
}


.pl-103px {
  padding-left: 103px;
}

.mobile-desktop-bg {
  background-image: url(../src/assets/images/mobile-desktop-bg.png);
  background-size: cover;
  background-position: bottom;
}

.video-bg {
  background-image: url(../src/assets/images/video-bg.png);
  background-size: auto;
  background-position: top;
  background-repeat: no-repeat;
}

.section-bg {
  background-image: url(../src/assets/images/section-bg.png);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.exp-bg {
  background-image: url(../src/assets/images/exp-bg.png);
  background-size: cover;
  background-position: bottom;
}

.p-48-60 {
  padding: 48px 60px;
}

.p-48 {
  padding: 48px;
}

.p-48-41 {
  padding: 48px 41.5px;
}

.p-85 {
  padding-left: 85px;
}

.m-t-4 {
  margin-top: 4px;
}

.m-t-20 {
  margin-top: 20px;
}

.faster-card {
  border: 1px solid #A2B9FF;
  background: #D6DFFC;
  border-radius: 32px;
}

.recon-card {
  background: #FFEEDB;
  border: 1px solid #FFD2A1;
  border-radius: 32px;
}

.pink-card {
  background: #EEDDFD;
  border: 1px solid #dab2ff;
  border-radius: 32px;
}

.green-card {
  border-radius: 32px;
  border: 1px solid #68DAB3;
  background: #CFECE2;
}

.text-green-v {
  color: #0E9F6E;
}

.text-warning-v {
  color: #E97900;
}

.text-pink-v {
  color: #A855F7;
}

.text-primary {
  color: #305FF0 !important;
}

.font-size-72 {
  font-size: 72px;
}

.font-size-32 {
  font-size: 32px;
}

.p-24-26 {
  padding: 24px 26px;
}

.f-g-1 {
  flex-grow: 1;
}

.g-30 {
  gap: 30px;
}

.ptb-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.tab-bg {
  background: #EEF3FF;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.06);
  padding: 16px;
  border-radius: 16px;
  display: flex;
  margin: auto;
  width: fit-content;
  justify-content: center;
  position: relative;
  margin-bottom: -32px;
  z-index: 1;

}

.tab-bg .nav-pills .nav-item a {
  padding: 12px 48px;
  font-size: 18px;
  line-height: 24px;
  border-radius: 10px;
  color: #515561;
}

.tab-bg .nav-pills .nav-item a.active {
  color: #fff;
}

.snapshot-tabs .tab-content {
  background: #EEF3FF;
}

.first-btn {
  background: #A855F7;
}

.p-4px {
  padding: 4px;
}

.gap-12 {
  gap: 12px;
}

.group-btns button {
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.06);
}

.gap-56 {
  gap: 56px;
}

.border-c0d0ff {
  border: 1px solid #c0d0ff;
}

.vertical-tabs li a {
  font-size: 18px;
  padding: 38px 24px;
  color: #23252d;
  border-radius: 0 !important;
  border-bottom: 1px solid #c0d0ff;
}

.vertical-tabs li:last-child a {
  border-bottom: 0 !important;
}

.vertical-tabs li a span {
  background: #EBF0FF;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  color: #305ff0;

}

.vertical-tabs li a.active span {
  background: #305ff0;
  color: #fff;
}

.vertical-tabs li a.active span i {

  vertical-align: middle;
  line-height: 1;
}

.vertical-tabs li a span i {
  vertical-align: middle;
}

.vertical-tabs li:first-child a {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.vertical-tabs li:last-child a {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

.vertical-tabs .nav-link.active {
  background: #EBF0FF;
  color: #305FF0;
}

.b-r-16 {
  border-radius: 16px;
}

.carousel-inner {
  background: #EBF0FF;
  padding: 48px 32px 12px 64px;
  border-radius: 16px;
}

.testimonials-carousel .carousel-inner {
  background: #EBF0FF;
  padding: 20px;
  border-radius: 24px;
}

.test-card {
  box-shadow: 0px 33px 88.7px 0px rgba(30, 90, 190, 0.20);
}

.carousel-indicators [data-bs-target] {
  width: 12px;
  height: 12px;
  border-radius: 15px;
  border-top: 0;
  border-bottom: 0;
}

.carousel-indicators .active {
  width: 32px !important;
  background: #305ff0 !important;
}

.l-h-1 {
  line-height: 1;
}

.color-1 {
  background: #A855F7;
}

.color-2 {
  background: #FFA84A;
}

.color-3 {
  background: #0E9F6E;
}

.color-4 {
  background: #5D85FF;
}

.sheild-bg {
  background-image: url(../src/assets/images/sheild-bg.png);
  background-size: cover;
  background-position: bottom;
}

.m-b-32 {
  margin-bottom: 32px;
}

.p-t-32 {
  padding-top: 32px;
}

.text-dec-none {
  text-decoration: none;
}

.hr-d-color {
  border-color: #D3D4D6;
}

.footer-inp {
  height: 40px;
  border-radius: 8px;
}

.color-width {
  width: 28px;
  height: 28px;
}

.icons-power {
  background: #EBF0FF;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 4px;
  color: #305FF0;
}

.v-align-middle {
  vertical-align: middle;
}

.max-1440px {
  max-width: 1440px;
}

.psr-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.test-span {
  color: #2548B5;
  border: 2px solid #c0d0ff;
  border-radius: 16px;
  padding: 4px 16px;
}

.test-card {
  border: 1px solid #d3d4d6;
}

.font-size-40 {
  font-size: 40px;

}

.b-r-48 {
  border-radius: 48px;
}

.p-56 {
  padding: 56px;
}

.price-shadow {
  box-shadow: 0px 0px 26.9px 3px rgba(132, 132, 132, 0.26);
}

.p-2-8-px {
  padding: 2px 8px;
}

.center-scale {
  transform: scale(1.1);
  z-index: 1;
}

.padding-bt-48 {
  padding-bottom: 48px;
}

.faq-padding {
  padding: 32px 40px 40px 40px;
}

.faq-bg {
  background: #F7F9FC;
}

.b-r-32 {
  border-radius: 32px;
}

.m-t-64 {
  margin-top: 64px;
}

.psr-80 {
  padding-left: 60px;
  padding-right: 60px;
}

.text-eb {
  color: #305ff078 !important;
}

.sketch-float {
  position: absolute;
  top: -52px;
  right: 29%;
}

.sketch-float span {
  position: absolute;
  width: max-content;
}

.bg-primary-50 {
  background: #ebf0ff;
}

.figma-bg {
  background-color: #f7f8fa !important;
}

.table-vch-card {
  border-color: #D3D4D6;
}

.btn-link {
  text-decoration: none;
}

.form-block {
  background-color: #fff;
  border-radius: 24px;
  padding: 48px;
  box-shadow: 0 4.981px 26.567px 6.642px #5cd0ff66;
}

.form-input-style {
  color: #23252d;
  border: 1px solid #d3d4d6;
  border-radius: 8px;
  height: 48px;
  font-size: 14px;

}

.form-mb-24 {
  margin-bottom: 24px;
}

.select-dropdown .p-dropdown {
  height: 48px;
  width: 100%;
  border-radius: 8px;
  color: #23252d;
  border: 1px solid #d3d4d6;
  font-size: 14px;
}

.select-dropdown .p-dropdown-label {
  padding-left: 10px;
  font-size: 14px;
  line-height: 28px;
}

.pt-120 {
  padding-top: 120px;
}

.p-24 {
  padding: 24px;
}

.v-hidden {
  visibility: hidden;
}

.f-32 {
  font-size: 32px;
}

.ps-48 {
  padding-left: 48px;
  padding-right: 48px;
}

.p-rel {
  position: relative;
}

.mobile-container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.mobile-p-s-e-0 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.navbar-toggler-icon {
  background-image: none;
  height: auto;
  width: auto;
}

.mobile-header-btns .btn-padding {
  padding: 8px 16px;
  font-size: 14px;
}

.tp-rocket {
  position: absolute;
  width: auto;
  bottom: -50px;
  left: 21%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mobile-font-24 {
    font-size: 22px;
  }

  .home-video {
    box-shadow: 0px 4.981px 26.567px 6.642px rgba(192, 208, 255, 0.40);
    border: 5px solid #A2B9FF;
    border-radius: 20px;
  }

  .p-dialog .p-dialog-header .p-dialog-title {
    font-size: 18px !important;
  }

  .mobile-p-40-64 {
    padding: 24px 20px !important;
  }

  .terms-li li {
    font-size: 14px !important;
  }

  .mobile-font-14 {
    font-size: 14px;
  }

  .mobile-p-10 {
    padding: 10px;
  }

  .mobile-faq-padding {
    padding: 20px;
  }

  .mobile-faq-padding button p.font-size-18 {
    font-size: 16px;
    text-align: left;
  }

  .sketch-float {
    top: -17px !important;
  }

  .mobile-faq-padding p.font-size-16 {
    font-size: 14px;
  }

  .mobile-font-16 {
    font-size: 16px;
  }

  .mobile-font-64 {
    font-size: 38px;
  }

  .mobile-font-audit {
    font-size: 32px;
  }

  .mobile-font-small {
    font-size: 18px;
  }

  .mobile-fast-cards {
    box-shadow: 1px 2px 4px 1px rgba(107, 114, 128, 0.20) !important;
    border-radius: 16px !important;
  }

  .mobile-full-85 {
    width: 85% !important;
  }

  .mobile-tab-content .tab-pane {
    padding-left: 0;
    padding-right: 0;
  }

  .control-section-mobile .p-24-26 {
    padding: 12px 16px;
  }

  .snapshots-section .tab-content {
    overflow: auto;
  }

  .mobile-pe-0 {
    padding-right: 0;
  }

  .mobile-padding-80 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .mobile-pills {
    flex-wrap: nowrap;
    overflow: auto;

  }

  .mobile-pills .nav-item .nav-link {
    width: max-content;
    padding: 16px 24px;
    font-size: 16px;
  }

  .mobile-m-t-64 {
    margin-top: 32px;
  }

  .mobile-m-t-32 {
    margin-top: 24px;
  }

  .mobile-p-t-42 {
    padding-top: 40px;
  }

  .mobile-p-b-42 {
    padding-bottom: 40px;
  }

  .mobile-p-t-48 {
    padding-top: 48px;
  }

  .mobile-p-b-48 {
    padding-bottom: 48px;
  }

  .full-width {
    width: 100% !important;
  }

  .center-scale {
    transform: inherit;
  }

  .mobile-columns .columns-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .mobile-p-0 {
    padding: 0 !important;
  }

  .mobile-snapshots-section .tab-pane {
    padding: 5px !important;
  }

  .mobile-p-56 {
    padding: 24px;
  }

  .mobile-bottom-16 {
    margin-bottom: 16px;
  }

  .mobile-font-icons button {
    font-size: 12px;
    padding: 8px;
  }

  .mobile-image-none {
    display: none;
  }

  .mobile-pl-0 {
    padding-left: 10px;
  }

  .mobile-auto-height {
    height: auto !important;
  }

  .mobile-m-t-24 {
    margin-top: 12px;
  }

  .mobile-bottom-8 {
    margin-bottom: 8px;
  }

  .section-hide {
    display: none;
  }

  .mobile-full-width {
    width: 100% !important;
    text-align: center;
  }

  .mobile-top-0 {
    padding-top: 0;
  }

  .mobile-top-0-imp {
    padding-top: 0 !important;
  }

  .mobile-bottom-0-imp {
    padding-bottom: 0 !important;
  }

  .mobile-text-center {
    text-align: center !important;
  }

  .mobile-text-start {
    text-align: left !important;
  }

  .main-heading-top {
    margin-top: 58px;
  }

  .mobile-margin-0 {
    margin-top: 0;
  }

  .mobile-visible {
    display: block !important;
  }

  .snapshot-tabs .nav-pills {
    flex-wrap: nowrap;
    overflow: auto;
  }

  .snapshot-tabs .tab-bg {
    margin-bottom: -34px;
    width: fit-content;
  }

  .mobile-pt-32 {
    padding-top: 32px;
  }

  .mobile-padding-top {
    padding-top: 56px;
  }


  .mobile-pills button {
    width: 100%;
    font-size: 12px;
  }

  .mobile-pills .mobile-unjustify {
    justify-content: inherit !important;
  }

  .mobile-top-12 {
    margin-top: 12px;
  }

  .text-center {
    text-align: center;
  }

  .gap-56 {
    gap: 12px;
  }

  .vertical-tabs li a span {
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 10px;
  }

  .vertical-tabs li a {
    padding: 12px 20px;
    font-size: 16px;
  }

  #kitaab-navbar {
    padding: 24px 12px;
  }

  #kitaab-navbar ul li a {
    padding-left: 0;
  }

  #kitaab-navbar .navbar-nav {
    margin-left: 0;
  }

  .glimpse-vertical-tabs .tab-content {
    padding: 0;
  }

  .glimpse-vertical-tabs .carousel-inner {
    padding: 16px;
  }

  .mobile-p-48 {
    padding: 20px;
  }

  .mobile-ps-0 {
    padding-left: 2px;
  }

  .mobile-pills .btn-padding {
    padding: 8px 16px;
  }

  .mobile-pills .color-width {
    width: 20px;
    height: 20px;
    line-height: 16px;
  }

  .mobile-pills .color-width i {
    font-size: 12px;
  }

  .snapshot-tabs .tab-content {
    border-radius: 0px;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 0px solid rgba(0, 0, 0, 0.18);
    border-left: 0;
    border-right: 0;
    background-color: #C8C9CB;
  }

  .mobile-accordion .accordion-button:not(.collapsed),
  .mobile-accordion .accordion-button:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
    background-color: transparent;
  }

  .mobile-accordion .accordion-button::after {
    font-family: uicons-bold-straight !important;
    font-style: normal;
    font-weight: 400 !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    background-image: none;
    content: "\f150";
    font-size: 12px;
    line-height: 20px;
  }

  /* .mobile-accordion .accordion-button.collapsed::after {
    background-color: var(--bs-gray-300);
  } */

  .border-accordion {
    border: 1px solid #f7f8fa !important;
    border-radius: 6px !important;
  }

  .border-accordion button {
    border-radius: 8px !important;
    color: #23252D;
  }

  .glimpse-section {
    display: none;
  }

  .mobile-glimpse-section {
    display: block !important;
  }

  .accordion-body .carousel-inner {
    padding: 16px;
    padding-right: 6px;
  }

  .accordion-button {
    padding: 16px !important;
    font-size: 16px;
  }

  .why-kitaab-section {
    display: none;
  }

  .mobile-kitaab-section {
    display: block !important;
  }

  .snapshots-section {
    display: none;
  }

  .mobile-snapshots-section {
    display: block !important;
  }

  .mobile-quote-top img,
  .mobile-quote-bottom img {
    width: 32px;
  }

  .mobile-quote-top {
    top: -14px !important;
  }

  .mobile-quote-bottom {
    position: absolute;
    margin-top: -32px;
    right: 30px;
    width: 100%;
  }

  .mobile-quote-bottom img {
    margin-right: 0;
  }

  .mobile-snapshots-section .tab-bg .nav-pills .nav-item a {
    padding: 8px 12px;
    font-size: 14px;
  }

  .mobile-p-t-64 {
    padding-top: 48px !important;
  }

  .color-1 {
    color: #A855F7;
    background: transparent;
  }

  .color-2 {
    color: #FFA84A;
    background: transparent;
  }

  .color-3 {
    color: #0E9F6E;
    background: transparent;
  }

  .color-4 {
    color: #5D85FF;
    background: transparent;
  }

  .gap-15 {
    gap: 15px;
  }

  .mobile-glimpse-section button {
    padding: 16px;
  }

  .bottom-color {
    border-bottom: 1px solid #c0d0ff;
  }

  .mobile-glimpse-section button span {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background: #EBF0FF;
    display: inline-flex;
    text-align: center;
    line-height: 42px;
    justify-content: center;
    vertical-align: middle;
  }

  .hr-color {
    border-color: #c0d0ff;
    opacity: 1;
  }

  .glimpse-open {
    box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    border-top: 4px solid #305ff0;
  }

  .glimpse-close {

    border-radius: 16px;
    border-top: 4px solid transparent;
  }

  .glimpse-active span {
    background: #305FF0 !important;
    color: #fff;
  }

  .hr-tp-color {
    border-color: transparent;
  }

}

@media only screen and (max-width: 768px) {
  .mobile-font-24 {
    font-size: 22px;
  }

  .home-video {
    box-shadow: 0px 4.981px 26.567px 6.642px rgba(192, 208, 255, 0.40);
    border: 5px solid #A2B9FF;
    border-radius: 20px;
  }

  .terms-li li {
    font-size: 14px !important;
  }

  .mobile-font-14 {
    font-size: 14px;
  }

  .sketch-float {
    top: -17px !important;
  }

  .mobile-p-10 {
    padding: 10px;
  }

  .mobile-font-16 {
    font-size: 16px;
  }

  .mobile-font-64 {
    font-size: 38px;
  }

  .mobile-font-audit {
    font-size: 32px;
  }

  .mobile-font-small {
    font-size: 18px;
  }

  .mobile-fast-cards {
    box-shadow: 1px 2px 4px 1px rgba(107, 114, 128, 0.20) !important;
    border-radius: 16px !important;
  }

  .mobile-full-85 {
    width: 85% !important;
  }

  .mobile-tab-content .tab-pane {
    padding-left: 0;
    padding-right: 0;
  }

  .control-section-mobile .p-24-26 {
    padding: 12px 16px;
  }

  .snapshots-section .tab-content {
    overflow: auto;
  }

  .mobile-pe-0 {
    padding-right: 0;
  }

  .mobile-padding-80 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .mobile-pills {
    flex-wrap: nowrap;
    overflow: auto;

  }

  .mobile-pills .nav-item .nav-link {
    width: max-content;
    padding: 16px 24px;
    font-size: 16px;
  }

  .mobile-m-t-64 {
    margin-top: 32px;
  }

  .mobile-m-t-32 {
    margin-top: 24px;
  }

  .mobile-p-t-42 {
    padding-top: 40px;
  }

  .mobile-p-b-42 {
    padding-bottom: 40px;
  }

  .mobile-p-t-48 {
    padding-top: 48px;
  }

  .mobile-p-b-48 {
    padding-bottom: 48px;
  }

  .full-width {
    width: 100% !important;
  }

  .center-scale {
    transform: inherit;
  }

  .mobile-columns .columns-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .mobile-p-0 {
    padding: 0 !important;
  }

  .mobile-snapshots-section .tab-pane {
    padding: 5px !important;
  }

  .mobile-p-56 {
    padding: 24px;
  }

  .mobile-bottom-16 {
    margin-bottom: 16px;
  }

  .mobile-font-icons button {
    font-size: 12px;
    padding: 8px;
  }

  .mobile-image-none {
    display: none;
  }

  .mobile-pl-0 {
    padding-left: 10px;
  }

  .mobile-auto-height {
    height: auto !important;
  }

  .mobile-m-t-24 {
    margin-top: 12px;
  }

  .mobile-bottom-8 {
    margin-bottom: 8px;
  }

  .section-hide {
    display: none;
  }

  .mobile-full-width {
    width: 100% !important;
    text-align: center;
  }

  .mobile-top-0 {
    padding-top: 0;
  }

  .mobile-top-0-imp {
    padding-top: 0 !important;
  }

  .mobile-bottom-0-imp {
    padding-bottom: 0 !important;
  }

  .mobile-text-center {
    text-align: center !important;
  }

  .mobile-text-start {
    text-align: left !important;
  }

  .main-heading-top {
    margin-top: 58px;
  }

  .mobile-margin-0 {
    margin-top: 0;
  }

  .mobile-visible {
    display: block !important;
  }

  .snapshot-tabs .nav-pills {
    flex-wrap: nowrap;
    overflow: auto;
  }

  .snapshot-tabs .tab-bg {
    margin-bottom: -34px;
    width: fit-content;
  }

  .mobile-pt-32 {
    padding-top: 32px;
  }

  .mobile-padding-top {
    padding-top: 56px;
  }


  .mobile-pills button {
    width: 100%;
    font-size: 12px;
  }

  .mobile-pills .mobile-unjustify {
    justify-content: inherit !important;
  }

  .mobile-top-12 {
    margin-top: 12px;
  }

  .text-center {
    text-align: center;
  }

  .gap-56 {
    gap: 12px;
  }

  .vertical-tabs li a span {
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 10px;
  }

  .vertical-tabs li a {
    padding: 12px 20px;
    font-size: 16px;
  }

  #kitaab-navbar {
    padding: 24px 12px;
  }

  #kitaab-navbar ul li a {
    padding-left: 0;
  }

  #kitaab-navbar .navbar-nav {
    margin-left: 0;
  }

  .glimpse-vertical-tabs .tab-content {
    padding: 0;
  }

  .glimpse-vertical-tabs .carousel-inner {
    padding: 16px;
  }

  .mobile-p-48 {
    padding: 20px;
  }

  .mobile-ps-0 {
    padding-left: 2px;
  }

  .mobile-pills .btn-padding {
    padding: 8px 16px;
  }

  .mobile-pills .color-width {
    width: 20px;
    height: 20px;
    line-height: 16px;
  }

  .mobile-pills .color-width i {
    font-size: 12px;
  }

  .snapshot-tabs .tab-content {
    border-radius: 0px;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 0px solid rgba(0, 0, 0, 0.18);
    border-left: 0;
    border-right: 0;
    background-color: #C8C9CB;
  }

  .mobile-accordion .accordion-button:not(.collapsed),
  .mobile-accordion .accordion-button:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
    background-color: transparent;
  }

  .mobile-accordion .accordion-button::after {
    font-family: uicons-bold-straight !important;
    font-style: normal;
    font-weight: 400 !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    background-image: none;
    content: "\f150";
    font-size: 12px;
    line-height: 20px;
  }

  /* .mobile-accordion .accordion-button.collapsed::after {
    background-color: var(--bs-gray-300);
  } */

  .border-accordion {
    border: 1px solid #f7f8fa !important;
    border-radius: 6px !important;
  }

  .border-accordion button {
    border-radius: 8px !important;
    color: #23252D;
  }

  .glimpse-section {
    display: none;
  }

  .mobile-glimpse-section {
    display: block !important;
  }

  .accordion-body .carousel-inner {
    padding: 16px;
    padding-right: 6px;
  }

  .accordion-button {
    padding: 16px !important;
    font-size: 16px;
  }

  .why-kitaab-section {
    display: none;
  }

  .mobile-kitaab-section {
    display: block !important;
  }

  .snapshots-section {
    display: none;
  }

  .mobile-snapshots-section {
    display: block !important;
  }

  .mobile-quote-top img,
  .mobile-quote-bottom img {
    width: 32px;
  }

  .mobile-quote-top {
    top: -14px !important;
  }

  .mobile-quote-bottom {
    position: absolute;
    margin-top: -32px;
    right: 30px;
    width: 100%;
  }

  .mobile-quote-bottom img {
    margin-right: 0;
  }

  .mobile-snapshots-section .tab-bg .nav-pills .nav-item a {
    padding: 8px 12px;
    font-size: 14px;
  }

  .mobile-p-t-64 {
    padding-top: 48px !important;
  }

  .color-1 {
    color: #A855F7;
    background: transparent;
  }

  .color-2 {
    color: #FFA84A;
    background: transparent;
  }

  .color-3 {
    color: #0E9F6E;
    background: transparent;
  }

  .color-4 {
    color: #5D85FF;
    background: transparent;
  }

  .gap-15 {
    gap: 15px;
  }

  .mobile-glimpse-section button {
    padding: 16px;
  }

  .bottom-color {
    border-bottom: 1px solid #c0d0ff;
  }

  .mobile-glimpse-section button span {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background: #EBF0FF;
    display: inline-flex;
    text-align: center;
    line-height: 42px;
    justify-content: center;
    vertical-align: middle;
  }


  .hr-color {
    border-color: #c0d0ff;
    opacity: 1;
  }

  .glimpse-open {
    box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    border-top: 4px solid #305ff0;
  }

  .glimpse-close {

    border-radius: 16px;
    border-top: 4px solid transparent;
  }

  .glimpse-close button span i {
    color: #305FF0;
  }

  .glimpse-active span {
    background: #305FF0 !important;
    color: #fff;
  }

  .hr-tp-color {
    border-color: transparent;
  }

}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (width: 820px) {
  .mobile-font-24 {
    font-size: 22px;
  }

  .mobile-p-40-64 {
    padding: 24px 20px !important;
  }

  .terms-li li {
    font-size: 14px !important;
  }

  .mobile-font-14 {
    font-size: 14px;
  }

  .mobile-p-10 {
    padding: 10px;
  }

  .mobile-faq-padding {
    padding: 20px;
  }

  .mobile-faq-padding button p.font-size-18 {
    font-size: 16px;
    text-align: left;
  }

  .sketch-float {
    top: -17px !important;
  }

  .mobile-faq-padding p.font-size-16 {
    font-size: 14px;
  }

  .mobile-font-16 {
    font-size: 16px;
  }

  .mobile-font-64 {
    font-size: 38px;
  }

  .mobile-font-audit {
    font-size: 32px;
  }

  .mobile-font-small {
    font-size: 18px;
  }

  .mobile-fast-cards {
    box-shadow: 1px 2px 4px 1px rgba(107, 114, 128, 0.20) !important;
    border-radius: 16px !important;
  }

  .mobile-full-85 {
    width: 85% !important;
  }

  .mobile-tab-content .tab-pane {
    padding-left: 0;
    padding-right: 0;
  }

  .control-section-mobile .p-24-26 {
    padding: 12px 16px;
  }

  .snapshots-section .tab-content {
    overflow: auto;
  }

  .mobile-pe-0 {
    padding-right: 0;
  }

  .mobile-padding-80 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .mobile-pills {
    flex-wrap: nowrap;
    overflow: auto;

  }

  .mobile-pills .nav-item .nav-link {
    width: max-content;
    padding: 16px 24px;
    font-size: 16px;
  }

  .mobile-m-t-64 {
    margin-top: 32px;
  }

  .mobile-m-t-32 {
    margin-top: 24px;
  }

  .mobile-p-t-42 {
    padding-top: 40px;
  }

  .mobile-p-b-42 {
    padding-bottom: 40px;
  }

  .mobile-p-t-48 {
    padding-top: 48px;
  }

  .mobile-p-b-48 {
    padding-bottom: 48px;
  }

  .full-width {
    width: 100% !important;
  }

  .center-scale {
    transform: inherit;
  }

  .mobile-columns .columns-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .mobile-p-0 {
    padding: 0 !important;
  }

  .mobile-snapshots-section .tab-pane {
    padding: 5px !important;
  }

  .mobile-p-56 {
    padding: 24px;
  }

  .mobile-bottom-16 {
    margin-bottom: 16px;
  }

  .mobile-font-icons button {
    font-size: 12px;
    padding: 8px;
  }

  .mobile-image-none {
    display: none;
  }

  .mobile-pl-0 {
    padding-left: 10px;
  }

  .mobile-auto-height {
    height: auto !important;
  }

  .mobile-m-t-24 {
    margin-top: 12px;
  }

  .mobile-bottom-8 {
    margin-bottom: 8px;
  }

  .section-hide {
    display: none;
  }

  .mobile-full-width {
    width: 100% !important;
    text-align: center;
  }

  .mobile-top-0 {
    padding-top: 0;
  }

  .mobile-top-0-imp {
    padding-top: 0 !important;
  }

  .mobile-bottom-0-imp {
    padding-bottom: 0 !important;
  }

  .mobile-text-center {
    text-align: center !important;
  }

  .mobile-text-start {
    text-align: left !important;
  }

  .main-heading-top {
    margin-top: 58px;
  }

  .mobile-margin-0 {
    margin-top: 0;
  }

  .mobile-visible {
    display: block !important;
  }

  .snapshot-tabs .nav-pills {
    flex-wrap: nowrap;
    overflow: auto;
  }

  .snapshot-tabs .tab-bg {
    margin-bottom: -34px;
    width: fit-content;
  }

  .mobile-pt-32 {
    padding-top: 32px;
  }

  .mobile-padding-top {
    padding-top: 56px;
  }


  .mobile-pills button {
    width: 100%;
    font-size: 12px;
  }

  .mobile-pills .mobile-unjustify {
    justify-content: inherit !important;
  }

  .mobile-top-12 {
    margin-top: 12px;
  }

  .text-center {
    text-align: center;
  }

  .gap-56 {
    gap: 12px;
  }

  .vertical-tabs li a span {
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 10px;
  }

  .vertical-tabs li a {
    padding: 12px 20px;
    font-size: 16px;
  }

  #kitaab-navbar {
    padding: 24px 12px;
  }

  #kitaab-navbar ul li a {
    padding-left: 0;
  }

  #kitaab-navbar .navbar-nav {
    margin-left: 0;
  }

  .glimpse-vertical-tabs .tab-content {
    padding: 0;
  }

  .glimpse-vertical-tabs .carousel-inner {
    padding: 16px;
  }

  .mobile-p-48 {
    padding: 20px;
  }

  .mobile-ps-0 {
    padding-left: 2px;
  }

  .mobile-pills .btn-padding {
    padding: 8px 16px;
  }

  .mobile-pills .color-width {
    width: 20px;
    height: 20px;
    line-height: 16px;
  }

  .mobile-pills .color-width i {
    font-size: 12px;
  }

  .snapshot-tabs .tab-content {
    border-radius: 0px;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 0px solid rgba(0, 0, 0, 0.18);
    border-left: 0;
    border-right: 0;
    background-color: #C8C9CB;
  }

  .mobile-accordion .accordion-button:not(.collapsed),
  .mobile-accordion .accordion-button:focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
    background-color: transparent;
  }

  .mobile-accordion .accordion-button::after {
    font-family: uicons-bold-straight !important;
    font-style: normal;
    font-weight: 400 !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    background-image: none;
    content: "\f150";
    font-size: 12px;
    line-height: 20px;
  }

  /* .mobile-accordion .accordion-button.collapsed::after {
    background-color: var(--bs-gray-300);
  } */

  .border-accordion {
    border: 1px solid #f7f8fa !important;
    border-radius: 6px !important;
  }

  .border-accordion button {
    border-radius: 8px !important;
    color: #23252D;
  }

  .glimpse-section {
    display: none;
  }

  .mobile-glimpse-section {
    display: block !important;
  }

  .accordion-body .carousel-inner {
    padding: 16px;
    padding-right: 6px;
  }

  .accordion-button {
    padding: 16px !important;
    font-size: 16px;
  }

  .why-kitaab-section {
    display: none;
  }

  .mobile-kitaab-section {
    display: block !important;
  }

  .snapshots-section {
    display: none;
  }

  .mobile-snapshots-section {
    display: block !important;
  }

  .mobile-quote-top img,
  .mobile-quote-bottom img {
    width: 32px;
  }

  .mobile-quote-top {
    top: -14px !important;
  }

  .mobile-quote-bottom {
    position: absolute;
    margin-top: -32px;
    right: 30px;
    width: 100%;
  }

  .mobile-quote-bottom img {
    margin-right: 0;
  }

  .mobile-snapshots-section .tab-bg .nav-pills .nav-item a {
    padding: 8px 12px;
    font-size: 14px;
  }

  .mobile-p-t-64 {
    padding-top: 48px !important;
  }

  .color-1 {
    color: #A855F7;
    background: transparent;
  }

  .color-2 {
    color: #FFA84A;
    background: transparent;
  }

  .color-3 {
    color: #0E9F6E;
    background: transparent;
  }

  .color-4 {
    color: #5D85FF;
    background: transparent;
  }

  .gap-15 {
    gap: 15px;
  }

  .mobile-glimpse-section button {
    padding: 16px;
  }

  .bottom-color {
    border-bottom: 1px solid #c0d0ff;
  }

  .mobile-glimpse-section button span {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background: #EBF0FF;
    display: inline-flex;
    text-align: center;
    line-height: 42px;
    justify-content: center;
    vertical-align: middle;
  }

  .hr-color {
    border-color: #c0d0ff;
    opacity: 1;
  }

  .glimpse-open {
    box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.06);
    border-radius: 16px;
    border-top: 4px solid #305ff0;
  }

  .glimpse-close {

    border-radius: 16px;
    border-top: 4px solid transparent;
  }

  .glimpse-active span {
    background: #305FF0 !important;
    color: #fff;
  }

  .hr-tp-color {
    border-color: transparent;
  }

}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #kitaab-navbar {
    padding: 24px 24px;
  }

  .mobile-bottom-16 {
    margin-bottom: 16px;
  }

  .group-btns button {
    font-size: 12px;
  }

  .group-btns .color-width {
    width: 20px;
    height: 20px;
  }

  .vertical-tabs li a {
    padding: 26px 24px;
  }

  .refreshing-tabs .nav-item a {
    font-size: 14px;
  }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #kitaab-navbar {
    padding: 24px 80px;
  }

  .group-btns button {
    font-size: inherit;
  }

  .group-btns .color-width {
    width: 28px;
    height: 28px;
  }

  .vertical-tabs li a {
    padding: 38px 24px;
  }

  .refreshing-tabs .nav-item a {
    font-size: 18px;
  }
}

.mobile-visible {
  display: none;
}

.font-size-10 {
  font-size: 10px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mobile-glimpse-section {
  display: none;
}

.mobile-kitaab-section {
  display: none;
}

.center-icons i {
  line-height: 1;
}

.kitaab-section-accordion .accordion-item {
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 12px !important;
  background: transparent !important;
}

.kitaab-section-accordion .accordion-button {
  background: #fff !important;
  border-radius: 12px !important;
}

.kitaab-section-accordion .accordion-body {
  padding: 32px 16px 24px 16px !important;
  background: #fff !important;
  border-radius: 8px !important;
  margin-top: 16px;
}

.kitaab-section-accordion .accordion-header button[aria-expanded="true"] {
  background-color: #305ff0 !important;
  color: #fff !important;
}

.mobile-kitaab-section {
  background: #EEF3FF;
}

.mr-8 {
  margin-right: 8px;
}

.m-t-5px {
  margin-top: 5px;
}

.mobile-snapshots-section {
  display: none;
}

.mobile-glimpse-accordion .accordion-item {
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.06);
}

.mobile-glimpse-accordion .accordion-header button[aria-expanded="true"] {
  border-top: 4px solid #305FF0 !important;
}

.mobile-glimpse-accordion .accordion-header .accordion-button {
  border-top: 4px solid #fff !important;
}


.w-150px {
  width: 150px;
}

.pr-24 {
  padding-right: 24px;
}

.pr-34 {
  padding-right: 34px;
}

.text-success {
  color: #0E9F6E !important;
}

.p-l-r-20 {
  padding-left: 25px;
  padding-right: 25px;
}

.m-t-69 {
  margin-top: 69px;
}

.m-t-37 {
  margin-top: 37px;
}

.m-t-86 {
  margin-top: 86px;
}

.m-t-106 {
  margin-top: 106px;
}

.m-t-54 {
  margin-top: 54px;
}

.m-t-42 {
  margin-top: 42px;
}

.m-r-8 {
  margin-right: 8px;
}

.p-40 {
  padding: 40px;
}

.testimonials-carousel .carousel-control-prev {
  left: -15%;
}

.testimonials-carousel .carousel-control-next {
  right: -15%;
}

.testimonials-carousel .carousel-indicators button {
  background: #c0d0ff;
}

.p-rel-imp {
  position: relative;
}

.quote-top {
  position: absolute;
  top: 30px;
  left: 10%;
}

.mr-12rem {
  margin-right: 12rem;
}

.paper-plane-float {
  position: absolute;
  right: 2px;
  top: 1px;
}

.paper-plane-float i {
  vertical-align: middle;
  line-height: 31px;
}

.terms-li li {
  font-size: 18px;
  color: #515561;
}

.hello-text {
  color: #162B6B;
}

.p-40-64 {
  padding: 40px 64px;
}

.m-t-10 {
  margin-top: 10px;
}

.manual-arrow-circle {
  width: 32px;
  height: 32px;
  background: #EBF0FF;
  color: #305ff0;
  display: block;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
}

.manual-top-bg {
  background-image: url(../src/assets/images/manual-head.png);
  background-size: cover;
  background-position: bottom;
}

.manual-search-inp {
  border: 1px solid #e2e3e4;
  background: #f7f8fa;
  height: 42px;
}

.w-90 {
  width: 90%;
}

.border-pm {
  border: 1px solid #C0D0FF;
}

.font-size-8 {
  font-size: 8px;
}

.no-card-bg {
  background: transparent;
}

.header-bottom-color {
  border-bottom: 1px solid #d3d4d6 !important;
}

/* Style the list */

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 16px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
  padding: 8px;
  color: #23252d;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: #305ff0;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}

.category-list-cards .card {
  border: 1px solid #d3d4d6;
  margin-bottom: 16px;
}

.right-sep-line {
  border-right: 1px solid #d3d4d6;
}

.category-side-search input {
  height: 36px;
  border: 1px solid #e2e3e4;
  background: #f7f8fa;
}

.side-list-btn-padding {
  padding: 4px 16px;
}

.submenu-padding {
  padding: 4px 12px;
}

.main-menu-button {
  margin-bottom: 8px;
}

.main-menu-button.active {
  margin-bottom: 0 !important;
}

.main-menu button {
  width: 100%;
  border-radius: 0;
  text-align: left;
}

.main-menu-button.active button {
  background: #EBF0FF;
  color: #305FF0;
}

.category-sub-menu {
  padding: 8px 20px 8px 36px;
  background: #f2f5ff;
  margin-bottom: 8px;
}

.category-sub-menu .sub-button {
  margin-bottom: 8px;
}

.op-1 {
  opacity: 1;
}

.collapse-expand-option button {
  position: absolute;
  right: -16px;
  top: 55px;
  border-radius: 50%;
  border: 1px solid #d3d4d6;
  width: 32px;
  height: 32px;
  line-height: 28px;
  text-align: center;
  font-size: 12px;
}

.collapse-after-left .hidden-content {
  visibility: hidden;
}

.collapse-after-left {
  width: 2%;
}

.collapse-after-right {
  width: 98%;
}

.ol-list-size li {
  font-size: 16px;
}

.faq-btn-padding {
  padding: 12px;
}

.ul-text-color li {
  color: #515561;
}

.b-l-line {
  border-left: 1px solid #d3d4d6;
}

.content-list button {
  padding: 8px 0px 0px 16px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.success-light-bg {
  background-color: #F3FAF7;
}

.danger-light-bg {
  background-color: #FDF2F2;
}

.c-pointer {
  cursor: pointer;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 14px;
  z-index: 1000;
}

.scroll-rounded-btn {
  width: 42px;
  height: 42px;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.06);
}

@media (min-width: 1400px) {
  .container-lg {
    max-width: 1600px;
  }
}

ul.p-dropdown-items {
  padding: 16px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  background: #f7f8fa;
  color: #23252D;
  border-radius: 8px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 14px;
}

.form-control:focus-within,
.form-control:focus {
  box-shadow: 0 0 0 2px #3060f08e !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 3px #3060f08e !important;
  border-color: transparent;
}

.address-cards-focus .card .card-body button i {
  line-height: 0;
}

.contact-shadow {
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.06);
  border: 1px solid #f7f8fa !important;
}

.contact-icon {
  background: #ebf0ff;
  line-height: 0;
  width: 84px;
  margin: auto;
  height: 84px;
  line-height: 89px;
  border-radius: 50%;
}

.font-size-42 {
  font-size: 42px;
}


.m-t-n-120 {
  margin-top: -100px;
}

.mb-60 {
  margin-bottom: 60px;
}

.home-video {
  /* box-shadow: 0px 4.981px 26.567px 6.642px rgba(192, 208, 255, 0.40);
  border: 5px solid #A2B9FF; */
  border-radius: 20px;
}

.home-dlg .p-dialog-header {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.home-dlg .p-dialog-content {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.home-dlg {
  border-radius: 16px;
}

.video-class {
  border: 1px solid #EBF0FF;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 24px;
}

.watch-tour-btn {
  position: absolute;
  top: 45%;
  width: 100%;
  display: none;
}

.watch-tour-btn .btn {
  background: #A2B9FF;
  border-color: #A2B9FF;
  color: #fff;
}

.watch-tour:hover .watch-tour-btn {
  display: block;
}

.related-articles {
  list-style: none;
  padding-left: 0;
}

.related-articles li {
  margin-bottom: 16px;
}

/* Style the list */
.guide-breadcrumb.breadcrumb {
  padding: 10px 0px;
  list-style: none;
  margin-bottom: 0;
}

/* Display list items side by side */
.guide-breadcrumb.breadcrumb li {
  display: inline;
  font-size: 14px;
}

/* Add a slash symbol (/) before/behind each list item */
.guide-breadcrumb.breadcrumb li+li:before {
  padding: 8px;
  color: #23252d;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
.guide-breadcrumb.breadcrumb li a {
  color: #305ff0;
  text-decoration: none;
}

/* Add a color on mouse-over */
.guide-breadcrumb.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}

.collapse-content-sidebar .create-collapse-content-hidden .home-buttons-span {
  display: none;
}

.landing-cards .card {

  transition: all 0.2s ease;
  cursor: pointer;
}

.landing-cards .card:hover {

  transform: scale(1.01);
}

.landing-cards .card button.btn-outline-light {
  background: #F7F8FA !important;
}

.tutorails-vertical-btns button.active {
  background: #EBF0FF;
  color: #305ff0;
}

.tutorails-vertical-btns button:hover {
  background: #EBF0FF;
}

.user-guide-pattern {
  /* background-image: url(../src/assets/images/user-guide-banner.png); */
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto 20rem;
  background-blend-mode: hard-light;
}

.p-3-f {
  padding: 24px;
}

.p-3-20 {
  padding: 20px;
}

.search-abs {
  position: absolute;
  right: 12px;
}

.related-articles li a img {
  border: 5px solid #EBF0FF;
  border-radius: 8px;
}

.plain-label {
  background: #EBF0FF;
  border-radius: 15px;
  border: 1px solid #d3d4d6;
  color: #6f7380;
  padding: 2px 12px;
}

.progress-circle-container {
  position: relative;
  display: inline-block;
  margin: auto;
}

.progress-circle {
  transform: rotate(-90deg);
}

.progress-circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 600;
}

.p-multiselect {
  border: 1px solid #d3d4d6;
  border-radius: 8px;
}

.p-multiselect-token {
  font-size: 12px;
  padding: 2px 6px;
  background: #EBF0FF;
  color: #23252d;
}

.p-multiselect-token svg {
  width: 10px;
}

.p-multiselect-items {
  padding-left: 0px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
  background: #EBF0FF;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
  background: #F7F8FA;
}

.p-multiselect .p-multiselect-label {
  padding: 6px 12px;
}

.custom-inp-width .p-inputtext {
  border: 1px solid #d3d4d6;
  border-radius: 8px;
  width: 100%;
  color: #23252d;
  height: 48px;
}

.p-fileupload .p-button {
  background: #305ff0;
  border-radius: 8px;
}

.p-fileupload .p-button-icon-only {
  color: #fff;
}

.finsights-data-table .p-datatable .p-datatable-tbody>tr>td {
  padding: 16px 24px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  color: #515151;
  border: 1px solid #d3d4d69c;

  border-width: 0 0 1px 0;
}

.finsights-data-table .p-datatable .p-datatable-thead>tr>th {
  padding: 12px 24px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px;
  background: #F9FAFB;
  color: #515561;
  border: 1px solid #d3d4d6a8;
  border-width: 0 0 1px 0;
}

.finsights-data-table .p-datatable .p-datatable-thead>tr>th:first-child {
  border-top-left-radius: 8px;
}

.finsights-data-table .p-datatable .p-datatable-thead>tr>th:last-child {
  border-top-right-radius: 8px;
}

.p-paginator {
  background: transparent;
  border: 0 !important;
  margin-top: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
}

.p-paginator .p-paginator-current {
  font-size: 12px;
  font-weight: 400;
  color: #777777;
  min-width: 2rem;
  height: 2rem;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  min-width: 2rem;
  height: 2rem;
}

.p-paginator .p-dropdown {
  border: 1px solid #f3f3f3;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #e8eaeb !important;
  border-color: #ffffff !important;
  color: #305FF0;
  font-size: 12px;
  font-weight: 500;
  box-shadow: none;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  font-size: 12px;
  font-weight: 500;
  color: #555;
  border-radius: 50px;
}

.p-paginator .p-paginator-current {
  margin-left: auto;
}

.p-paginator .p-dropdown {
  height: 1.75rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
  font-size: 12px;
  font-weight: 500;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}

.p-paginator .p-inputtext {
  padding: 0.219rem 0.429rem;
  color: #555;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.step-color-bg {
  background: #EBF0FF;
  border-radius: 25px;
  padding: 8px 16px;
}



.login-c-app-body {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(0px + 100vh);
  overflow: auto;
}


.login-sdw {
  box-shadow: 10px 0px 40px 41px rgba(197, 211, 255, 0.38);
}

.b-r-16 {
  border-radius: 16px;
}

.login-rect-bg {
  /* background-image: url(../../src/renderer/assets/images/rectangle-bg.png); */
  background-size: cover;
  background-position: bottom;
}

.login-ps-img {
  position: absolute;
  width: 550px;
}

.expire-ps-img {
  position: absolute;
  width: 600px;
}

.expire-shape {
  height: 500px;
  position: absolute;
  width: 635px;
  margin-top: -17px;
  margin-left: -51px;
}

.invited-shape {
  height: 630px;
  position: absolute;
  width: 635px;
  margin-top: -50px;
  margin-left: -51px;
}

.forgot-password-shape {
  position: absolute;
  width: 45%;
  height: 660px;
  left: 28%;
  top: -110px;
}

.login-congtainer-area {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 100vh;
}

.wrap-fp100 {
  width: 490px;
  overflow: hidden;
}

.sign-up-ps-img {
  position: absolute;
  width: 600px;
}

.sign-in-pattern {
  background-image: url(../src/assets/images/sign-in-ill.png);
  background-size: cover;
  background-position: bottom;
}

.h-40-input {
  height: 40px;
}

.invite-padding {
  padding: 60px 62px;
}
.error-message {
  font-size: 10px;
  color: red;
}